@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
html {
  scroll-behavior: smooth;
}
.font-all{

    font-family: 'Montserrat', sans-serif;
}
.bg-nav{
    background: rgba(203, 34, 41, 0.15);
border-width: 1px 0px;
border-style: solid;
border-color: #CB2229;
}

/* Track */
::-webkit-scrollbar {
   display: none;
  }

  ::-webkit-scrollbar-thumb {
   display: none;
  }
  
  .shadow-l{
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.15));
  }
.bg-layout{
    background: #FFFFFF;
border: 1px solid #F8F8F8;
box-shadow: 0px 0px 8px 3px rgba(203, 34, 41, 0.05);
border-radius: 10px;
}
.border-a{
        
border: 1px solid #738482;
}
.text-color{
  color: #263238;
  ;  
  

}
/* z */
.scrollbar::-webkit-scrollbar {
        width: 6px;
      }
      
      .scrollbar::-webkit-scrollbar-track {
        background: rgb(0, 0, 0);
      }
      
      .scrollbar::-webkit-scrollbar-thumb {
        background-color: rgb(211, 0, 0);
        border-radius: 3px;
      }